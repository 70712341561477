import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Gallery from "../components/gallery"

export default function GalleryPage({ data, pageContext }: any) {
  const items = [
    ...data.videos.edges.map((e: any) => e.node),
    ...data.images.edges.map((e: any) => e.node),
  ]

  return (
    <>
      <SEO title="Gallery" />
      <Gallery
        mediaItems={items}
        title="Gallery"
        subtitle={pageContext.dirName}
      />
    </>
  )
}

export const query = graphql`
  query($dirPath: String!) {
    images: allFile(
      filter: {
        relativeDirectory: { eq: $dirPath }
        extension: { in: ["jpeg", "JPEG", "jpg", "JPG", "png", "PNG"] }
      }
    ) {
      edges {
        node {
          mtimeMs
          name
          relativePath
          extension
          publicURL
          thumbs: childImageSharp {
            fluid(maxWidth: 300, srcSetBreakpoints: [5000], quality: 100) {
              aspectRatio
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    videos: allFile(
      filter: {
        relativeDirectory: { eq: $dirPath }
        extension: { in: ["mp4", "MP4"] }
      }
    ) {
      edges {
        node {
          mtimeMs
          name
          relativePath
          extension
          publicURL
        }
      }
    }
  }
`
